module.exports = `
<html prefix="og: http://ogp.me/ns#">

<head>
    <title>How to use Video Downloader to download TikTok | Musically Videos</title>
    <meta name="title" content="How to use Video Downloader to download TikTok | Musically Videos" />
    <meta name="robots" content="noindex,nofollow" />
    <meta name="viewport" content="width=device-width, initial-scale=1" />
    <meta charset="UTF-8" />
    <link rel="stylesheet" href="../../css/main.css" />
</head>
<style>
    .bordered-text {
        display: inline-block;
        width: 55%;
        border: 1px solid #eee;
        padding: 10px;
        border-radius: 4px;
    }

    .guide-image {
        width: 40%;
    }
</style>

<body>
    <div class="container-fluid px-0 bg-white body-wrapper">
        <div class="container px-0" style="padding-bottom:50px;max-width:500px">
            <div style="background:#FAFAFA;padding:20px">Steps to download videos from TikTok using Video Downloader</div>
            <div style="padding:20px">
                <div class="bordered-text mr-1">Open TikTok app and tap on Share Icon <i class='fa fa-share'
                        style='border-radius:50%;background:#DFDFDF;padding: 7px 7px 5px 7px;color: white;'></i> on any
                    video</div><img class="img-fluid guide-image"
                    src="https://raw.githubusercontent.com/videoder/main/master/public/web_images/musically-app-ss.webp"
                    alt="TikTok Screen Shot" />
            </div>
            <div style="padding:20px"><img class="img-fluid guide-image"
                    src="https://raw.githubusercontent.com/videoder/main/master/public/web_images/musically-copy-link.webp"
                    alt="TikTok Screen Shot" />
                <div class="bordered-text ml-1">Select copy link <i class='fa fa-link'
                        style='border-radius:50%;border:1px solid #eee;padding: 7px 7px 5px 7px;'></i> from the options
                    menu.</div>
            </div>
            <div style="padding:20px">
                <div class="bordered-text mr-1">Video Downloader will prompt you for downloading the video. Tap on the
                    download
                    button</div><img class="img-fluid guide-image"
                    src="https://raw.githubusercontent.com/videoder/main/master/public/web_images/musically-download-prompt.webp"
                    alt="TikTok Screen Shot" style="vertical-align: bottom;" />
            </div>
            <div style="padding:20px"><img class="img-fluid guide-image"
                    src="https://raw.githubusercontent.com/videoder/main/master/public/web_images/musically-select-resolution.webp"
                    alt="TikTok Screen Shot" style="vertical-align: bottom;" />
                <div class="bordered-text ml-1">Select the download resolution and your download will start</div>
            </div>
        </div>
    </div><a href="rubbit://open_third_party_app?package_id=com.zhiliaoapp.musically"
        style="position: fixed;bottom: 0;width: 100%;background-image: linear-gradient(-127deg, #F0897D 0%, #EE77D3 100%);box-shadow: 0 -2px 6px 0 rgba(0,0,0,0.28);text-align: center;padding: 10px 0;color: white;">OPEN
        TIKTOK APP</a>
</body>

</html>
`